/* Device specific overrides */

/* Don't use this file for getting your styles to 'work'; go through the styled components 
first and if you can't manipulate them there (e.g. its a UI Bricks component) then do it here. */

/* iPhone 5/SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    /* ADD STYLES HERE*/
    * {
        font-size: 0.75rem;
    }

    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 -0.25rem 0 -0.25rem;
    }
}

/* iPhone 6/7/8 */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    /* ADD STYLES HERE*/
    * {
        font-size: 0.9rem;
    }
    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 -0.25rem 0 -0.25rem;
    }
}

/* iPhone 6/7/8 Plus */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    /* ADD STYLES HERE*/
    * {
        font-size: 0.9rem;
    }
    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 -0.25rem 0 -0.25rem;
    }
}

/* iPhone X */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    /* ADD STYLES HERE*/
    * {
        font-size: 0.9rem;
    }
    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 -0.25rem 0 -0.25rem;
    }
}

/* Galaxy S4, S5 and Note 3 */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
    /* ADD STYLES HERE*/
    * {
        font-size: 0.9rem;
    }
    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 -0.25rem 0 -0.25rem;
    }
}

/* Google Pixel */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
    /* ADD STYLES HERE*/
    * {
        font-size: 0.9rem;
    }
    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 -0.25rem 0 -0.25rem;
    }
}

/* Google Pixel XL */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) {
    /* ADD STYLES HERE*/
    * {
        font-size: 0.9rem;
    }
    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 -0.25rem 0 -0.25rem;
    }
}

/* iPad 3, 4 and Pro 9.7" */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    /* ADD STYLES HERE*/
    html,
    body,
    #root,
    .App {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
    .App .dr-p202-page-ui {
        max-width: 75%;
        margin: 0 auto;
        padding: 0 1.6rem;
    }
    body {
        font-size: 1rem;
    }
    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 -0.25rem 0 -0.25rem;
    }
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media screen and (min-width: 1281px) {
    /* ADD STYLES HERE*/
    * {
        font-size: 1rem;
    }
    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 0.625rem;
    }
    #applicant-choice > div:not(:last-child) {
        margin-right: 3.125rem;
    }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    /* ADD STYLES HERE*/
    * {
        font-size: 1rem;
    }
    .App .input-container,
    .App .select-container {
        padding-bottom: 0.9rem;
        margin: 0 0.625rem;
    }
    #applicant-choice > div:not(:last-child) {
        margin-right: 3.125rem;
    }
}

.yearmonthInput > input {
    min-width: 50px;
}
