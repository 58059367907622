/* Card Animations */

.blur-in {
    -webkit-animation: blur-in 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
    animation: blur-in 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.slide-in-blurred-left {
    -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
    animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@-webkit-keyframes blur-in {
    0% {
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}
@keyframes blur-in {
    0% {
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}
@keyframes slide-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}
