/* Styles specific to the Credit App Application */

/* SDP (Embedded Experience) Overrides */
.App.embedded label,
.App.embedded span {
    font-size: 0.9rem;
    white-space: normal;
}

.App.embedded div.credit-decision-landing-page.dr-p202-page-ui {
    padding: 0
}

.App.embedded [data-testid='ui-bricks-modal']>div,
.App.embedded [data-testid='accelerate-custom-loader'] {
    margin-top: -3rem !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

label {
    color: rgb(34, 34, 34);
    font-weight: 600;
}

/* Button width for Applicant Choice */
#applicant-choice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#applicant-choice>div {
    width: 100%;
    max-width: 40vw;
}

/* for UI-bricks modals, enforce font-size on the header */
[data-testid='ui-bricks-modal']>div>div:first-child span {
    font-size: 1rem !important;
}

/* Reset font family for Audio Eye widget/icon */
aside#ae_launcher span[class*=icon-] :before{
    font-family: inherit;
}