/* P202 Resets e.g, PageUI components; Please don't change these, as we can't easily modify them elsewhere */

html,
body,
.App,
.App .dr-p202-page-ui,
#root {
    max-width: 50rem;
    margin: 0 auto;
}

.App .dr-p202-page-ui {
    /* Removes uneccessary margin and padding when PageUI is imported */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.App .header-logo {
    /* Removes margin and padding from the PageUI header logo */
    margin: 0 auto;
    padding: 0;
}

.App .page-subtitle {
    /* Removes margin from the PageUI subtitle */
    margin: 0 0 2rem 0;
}
